<template>
  <div class="profile-page-container">
    <div class="achievements-header">
    <v-icon class="top-icon">mdi-trophy-outline</v-icon>
      <h2 class="big-text">Osiągnięcia</h2>
      <p class="small-text">Lista osiągnięć.</p>
    </div>
    <div class="swiper achievements-container">
      <!-- Achievements will be scrolled horizontally -->
      <swiper
          :fade-effect="{ crossFade: true }"
          :loop="true"
          :slide-active-class="'slide-active'"
          slides-per-view="auto"
          :space-between="20"
          class="swiper-slider"
      >
        <swiper-slide v-for="achievement in achievementItems" :key="achievement.id" class="swiper-slide achievement">
          <div class="achievement-image-wrapper">
            <v-img :src="apiUrl + achievement.photoUrl" alt="Achievement Image"/>
          </div>
          <p class="achievement-date">{{ formatDate(achievement.day, achievement.month, achievement.year) }}</p>
          <p class="achievement-name">{{ achievement.name }}</p>
          <p class="achievement-description">{{ achievement.description }}</p>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>
<script>
import {Swiper, SwiperSlide} from 'swiper/vue';

export default {
  name: 'AchievementsPage',
  components: {
    Swiper,
    SwiperSlide
  },
  props: {
    achievementItems: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      apiUrl: process.env.VUE_APP_API_URL
    };
  },
  methods: {
    formatDate(day, month, year) {
      return [day, month, year].filter(Boolean).join('-');
    }
  },
};
</script>

<style lang="scss" scoped>

.achievements-header {
  text-align: center;
}

.achievements-container {
  width: 100%;
  height: fit-content !important;
  display: flex;
  overflow-x: hidden;
  margin: 20px 16px 0 !important;

  @media(min-width: 768px) {
    width: calc(100% + 60px);
  }
}

.achievement {
  width: 80% !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: #fff;
  padding: 16px;
  text-align: left;
  height: fit-content;

  @media (min-width: 480px) {
    width: 228px !important;
  }
}

.achievement-image-wrapper {
  height: 134px;
  display: flex;
  align-self: center;
}

.achievement-date {
  font-size: 14px;
  color: #666;
  margin-bottom: 8px;
}

.achievement-description {
  font-size: 16px;
  color: #333;
}

.achievement-name {
  font-size: 18px;
  font-weight: bold;
  color: #333;
  margin-top: 8px;
}
</style>
