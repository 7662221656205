<template>
  <div class="profile-page-container">
    <div
      v-if="additionalDescriptionData.photoUrl"
      class="image-placeholder"
    >
      <img
        :src="apiUrl + additionalDescriptionData.photoUrl.replace(/\\/g, '/')"
        alt="Additional Description Photo"
        class="photo-frame"
      />
    </div>
    <div class="generic-panel big-text">{{ additionalDescriptionData.title }}</div>
    <p class="description small-text">{{ additionalDescriptionData.description }}</p>
  </div>
</template>

<script>
export default {
  name: 'AdditionalDescriptionPage',
  props: {
    additionalDescriptionData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      apiUrl: process.env.VUE_APP_API_URL
    };
  }
};
</script>

<style lang="scss" scoped>

.image-placeholder {
  margin-bottom: 16px; // Adjust spacing as needed
}

.photo-frame {
  margin-top: 16px;
  border: 1px solid #000;
  height: 16rem; /* Set height to match EducationPage */
  display: flex;
  justify-content: center;
  align-items: center;
}


h1 {
  font-size: 48px; // Large font size for the date
  font-weight: bold;
  margin-top: 16px;
  margin-bottom: 8px; // Provide space between the title and description
}

// Reuse the .small-text style from profile.scss for the description and panel text
</style>
